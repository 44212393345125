@use '@dashboard-experience/mastodon/lib/theme.scss';

#mastodon {
  .reports-list-actions-search-bar {
    .mastodon-text-input {
      margin-bottom: 0;
    }

    width: 280px;
    align-self: flex-start;

    @media (max-width: 671px) {
      width: 100%;
    }
  }

  .reports-list-placeholder-button {
    width: 125px;
    height: 40px;
    background-color: blue;
    border-radius: 5px;
    color: white;
  }

  .reports-list-actions-flex-container {
    width: 100%;
    display: flex;
    gap: 12px;
    background-color: yellow;
  }

  .reports-list-actions-left {
    display: flex;
    flex-wrap: nowrap;
  }

  .reports-list-actions-filters-dropdown {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
  }

  .reports-list-actions-filters {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    gap: 4px;
  }

  .reports-list-actions-right {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    gap: 12px;
    margin-left: auto;
  }

  .reports-list-actions-divider {
    width: 2px;
    height: 20px;
    background-color: blue;
  }

  @media (max-width: 671px) {
    .reports-list-actions-left {
      flex-direction: column;
      flex: 1 0;
      gap: 4px;
    }

    .reports-list-actions-divider {
      display: none;
    }
  }

  @media (max-width: 400px) {
    .reports-list-actions-flex-container {
      flex-direction: column;
      gap: 4px;
    }
  }

  .reports-list-actions-button-container {
    .mastodon-button.secondary {
      padding: 6px 10px;
    }

    .mastodon-button.secondary:focus:enabled {
      border: 1px solid #e1e6eb;
      box-shadow: 0 0 0 0 !important;
    }
  }

  .reports-list-actions-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }
  .reports-list-actions-menu {
    padding: 8px 14px;
  }

  .reports-list-actions-save-button {
    .mastodon-button.secondary {
      background-color: theme.$ui-green-600;
      color: white;
      border: 1px solid theme.$ui-green-600 !important;

      svg {
        fill: white;
      }
    }

    .mastodon-button.secondary:hover:enabled:not(.disabled),
    .mastodon-button.secondary:active:enabled {
      background-color: theme.$ui-green-700 !important;
      border: 1px solid theme.$ui-green-800 !important;
    }
  }
}
